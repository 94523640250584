<template>
  <div class="iss-main">
    <a-row>
      <a-col :span="18"
        ><a-space :size="30">
          <span>推广内容：{{ conData.promoteName }}</span>
        </a-space>
      </a-col>
      <a-col :span="6" style="text-align: right">
        <a-space>
          <a-button @click="$router.go(-1)">返回</a-button>
        </a-space>
      </a-col>
    </a-row>

    <grid
      ref="gridRef"
      :columns="columns"
      :code="$route.path"
      :url="url"
      :url-params="{ promoteId: conData.id }"
      :search="search"
      :btn-operation="btnOperation"
      :scroll="{ x: 900, y: gridHeight }"
    >
      <template #posterUri="{ text }">
        <img :src="text.posterUri" width="130" />
      </template>
      <template #operation="{ record }">
        <operation :options="options" :record="record" />
      </template>
    </grid>

    <a-modal
      destroyOnClose
      :visible="visible"
      :mask-closable="false"
      :title="form.id ? '编辑海报' : '新增海报'"
      :width="1000"
      centered
      @ok="hadleClickBySave"
      @cancel="afterClose"
    >
      <a-form
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 16 }"
        class="ant-modal-body-height"
        ><a-row>
          <a-col :span="12">
            <a-form-item label="海报名称" v-bind="validateInfos.posterName">
              <a-input
                v-model:value="form.posterName"
                placeholder="请输入海报名称"
              />
            </a-form-item>
            <a-form-item
              label="海报图片"
              v-bind="validateInfos.posterUri"
              extra="建议上传宽高比为9:16，格式为JPG、JPEG或PNG且大小不超过5M的图片"
            >
              <iss-image-upload
                v-model:value="form.posterUri"
                list-type="picture-card"
                :limit-size="5"
                accept=".jpe,.jpeg,.jpg,.png"
              />
            </a-form-item>
            <a-form-item
              label="二维码内Logo"
              v-bind="validateInfos.qrcodeLogo"
              extra="请上传大小不超过1M，格式为JPG、JPEG或PNG的图片"
            >
              <iss-image-upload
                v-model:value="form.qrcodeLogo"
                isLt1MOpen
                list-type="picture-card"
                accept=".jpe,.jpeg,.jpg,.png"
              />
            </a-form-item>
            <a-form-item :wrapper-col="{ span: 14, offset: 6 }">
              <!--              <a-button-->
              <!--                style="margin-right: 20px"-->
              <!--                type="primary"-->
              <!--                @click="hadleClickBySave"-->
              <!--                >保存</a-button-->
              <!--              >-->
              <!--              <a-button @click="afterClose">取消</a-button>-->
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="编辑二维码">
              <span style="color: #00000073"
                >点击二维码，移动鼠标调整二维码大小和位置。</span
              ></a-form-item
            >
            <a-form-item :wrapper-col="{ offset: 2 }">
              <div
                class="img-drang"
                :style="{ width: '355px', height: imgHeght + 'px' }"
              >
                <img
                  style="width: 100%; height: auto"
                  :src="form.posterUri"
                  ref="imgRef"
                  @load="imgLoadHeght"
                />
                <VueDragResize
                  v-if="isReloadData"
                  isActive
                  isDraggable
                  isResizable
                  aspectRatio
                  parentLimitation
                  :w="dragQr.width"
                  :h="dragQr.width"
                  :x="dragQr.left"
                  :y="dragQr.top"
                  v-on:resizing="resize"
                  v-on:dragging="resize"
                >
                  <vue-qr
                    :logoSrc="form.qrcodeLogo"
                    :text="getPosterLink"
                    :margin="5"
                    :size="dragQr.width"
                  />
                </VueDragResize>
              </div>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-modal>
  </div>
</template>
<script>
import { Row, Col, Space, Form, message } from 'ant-design-vue';
import VueQr from 'vue-qr/src';
import Grid from '@/components/grid';
import { local } from '@/utils/storage';
import { reactive, toRefs, ref, nextTick } from 'vue';
import Operation from '@/components/operation';
import VueDragResize from 'vue-drag-resize/src';
// import { useRoute } from 'vue-router';

import promoteApi from '@/api/promote';
import IssImageUpload from '@/components/imageUpload';
export default {
  components: {
    ARow: Row,
    ACol: Col,
    ASpace: Space,
    AForm: Form,
    AFormItem: Form.Item,
    Grid,
    VueQr,
    Operation,
    IssImageUpload,
    VueDragResize,
  },
  setup() {
    const gridRef = ref();
    const imgRef = ref();
    const conData = local.get('configData');
    const state = reactive({
      visible: false,
      dragQr: { top: 10, left: 10, width: 100, height: 100 },
      imgHeght: '600',
      isReloadData: true,
    });
    const form = reactive({
      posterName: '',
      posterUri: null,
      qrcodeLogo: '',
    });

    const required = { required: true, message: '不能为空' };
    const { validateInfos, validate, resetFields } = Form.useForm(form, {
      posterName: [required, { max: 32, message: '不可超过32个字符' }],
      posterUri: [required],
      // qrcodeLogo: [required],
    });

    const handleByEnabled = async (id, enabled) => {
      let total = 0;
      await promoteApi.marketingPosterPage('', { promoteId: id }).then(res => {
        total += res?.total;
      });
      await promoteApi.marketingLinkSelect('', id).then(res => {
        res && (total += 1);
      });
      enabled && (total -= 1);
      total == 0 && promoteApi.promoteUpdateEnabled('', { id, enabled });
    };

    return {
      form,
      validateInfos,
      imgRef,
      gridRef,
      conData,
      gridHeight: document.body.clientHeight - 366,
      url: promoteApi.posterPageUrl,
      ...toRefs(state),
      columns: [
        {
          title: '#',
          dataIndex: 'index',
          key: 'index',
          align: 'center',
          width: 50,
          customRender: ({ index }) => `${index + 1}`,
        },
        { dataIndex: 'posterName', title: '海报名称' },
        {
          key: 'posterUri',
          title: '海报预览',
          slots: { customRender: 'posterUri' },
        },
        { dataIndex: 'createTime', title: '创建时间' },
        {
          key: 'id',
          title: '操作',
          width: 180,
          slots: { customRender: 'operation' },
        },
      ],
      options: [
        {
          type: 'update',
          label: '编辑',
          permission: 'meeting:update',
          fnClick: record => {
            state.dragQr.height = record.qrcodeLong;
            state.dragQr.width = record.qrcodeWidth;
            state.dragQr.top = record.qrcodeY;
            state.dragQr.left = record.qrcodeX;
            Object.assign(form, { ...record });
            state.visible = true;
          },
        },
        {
          type: 'delete',
          permission: 'meeting:delete',
          fnClick: ({ id }) => {
            promoteApi.posterDelete('', { ids: [id] }).then(() => {
              message.success('操作成功');
              gridRef.value.refreshGrid();
              handleByEnabled(conData.id, false);
            });
          },
        },
      ],
      btnOperation: [
        {
          type: 'add',
          permission: 'meeting:add',
          fnClick: () => {
            resetFields(); //清空form
            Object.keys(form).map(key => delete form[key]);
            form.posterUri = null;
            state.visible = true;
          },
        },
      ],
      getPosterLink: () => `链接`,
      resize: newRect => {
        state.dragQr = newRect;
      },
      imgLoadHeght: () => {
        state.isReloadData = false;
        state.imgHeght = imgRef.value.height;
        nextTick(() => {
          state.isReloadData = true;
          !form.id &&
            (state.dragQr = { width: 100, top: 10, left: 10, height: 100 });
        });
      },
      afterClose: () => {
        state.visible = false;
      },
      handleClickByAdd: () => {
        state.visible = true;
      },
      hadleClickBySave: () => {
        form.promoteId = conData.id;
        form.qrcodeLong = state.dragQr.height;
        form.qrcodeWidth = state.dragQr.width;
        form.qrcodeX = state.dragQr.left;
        form.qrcodeY = state.dragQr.top;
        nextTick(() => {
          validate().then(() => {
            promoteApi[form.id ? 'posterUpdate' : 'posterAdd']('', form).then(
              () => {
                message.success('操作成功');
                state.visible = false;
                gridRef.value.refreshGrid();
                !form.id && handleByEnabled(conData.id, true);
              }
            );
          });
        });
      },
    };
  },
};
</script>
<style lang="less" scoped>
.iss-main {
  max-height: calc(100vh - 88px);
  overflow-y: auto;
}
.img-drang {
  height: auto;
}
</style>
